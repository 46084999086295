@media print {
	#myPrntbtn {
		display: none;
	}
	#editprice {
		display: none;
	}
	#mail {
		display: none;
	}
	#downloadwprice {
		display: none;
	}
	#download {
		display: none;
	}
	#ticket {
		display: none;
	}
	#ticket2 {
		display: none;
	}
	#back {
		display: none;
	}
	#headerticket {
		display: none;
	}
	#mainheader {
		display: none;
	}
	#mainheader2 {
		display: none;
	}
	#title {
		display: none;
	}
	@page {
		margin-top: 0;
		margin-bottom: 0;
	}
	body {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
}
.MuiMenu-paper {
	max-height: 300;
}
.alice-carousel__prev-btn {
	display: none;
}
